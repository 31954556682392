.home {
  .form {
    padding: 20px;
    display: flex;

    &:first-child {
      padding-bottom: 0;
    }

    .inner {
      display: flex;
      position: relative;
      width: 100%;

      .input {
        width: calc(100% - 70px);
        max-width: 100%;
        min-width: auto;

        & > div {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      .button {
        width: 70px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .charts {
  }
}

.instance {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: .5px;

  .instance2 {
    display: flex;
  }

  .description {
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: .5px;
    border-bottom: solid #efefef 1px;
    margin-bottom: 10px;
  }
}
