@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
*{outline:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;-webkit-tap-highlight-color:transparent}input{-webkit-user-select:text;-ms-user-select:text;user-select:text}html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{color:#272e40;line-height:1;font-family:'Spoqa Han Sans', 'Malgun Gothic', Sans-Serif, serif}ol,ul{list-style:none}blockquote,q{quotes:none}table{border-collapse:collapse;border-spacing:0}

.App_app__3BT4Y{display:flex;flex-direction:column;width:100%;height:100%}

.LoginForm_loginForm__wwmzQ{display:flex;justify-content:center;align-items:center;position:fixed;left:0;top:0;width:100%;height:100%}.LoginForm_loginForm__wwmzQ .LoginForm_form__2VEPW{width:300px;display:flex;flex-direction:column}.LoginForm_loginForm__wwmzQ .LoginForm_form__2VEPW .LoginForm_title__1kIMZ{margin-bottom:12px;font-weight:normal;font-size:24px}.LoginForm_loginForm__wwmzQ .LoginForm_form__2VEPW .LoginForm_input__Rw8CY{margin-bottom:10px}.LoginForm_loginForm__wwmzQ .LoginForm_form__2VEPW .LoginForm_actions__2LhHq{display:flex}.LoginForm_loginForm__wwmzQ .LoginForm_form__2VEPW .LoginForm_actions__2LhHq .LoginForm_loginButton__2RRnU{width:100%}

.Button_button__2Lf63{-webkit-appearance:none;-moz-appearance:none;appearance:none;font-size:14px;box-sizing:border-box;padding:14px 16px;border-radius:4px;border:none;cursor:pointer;position:relative;display:flex;justify-content:center;box-shadow:0 1px 2px 0 rgba(0,0,0,0.1);transition:background 0.15s cubic-bezier(0.4, 0, 0.2, 1),transform 0.15s cubic-bezier(0.4, 0, 0.2, 1)}.Button_button__2Lf63.Button_primary__I_9I9{background:#396eff;color:#fff}.Button_button__2Lf63.Button_primary__I_9I9:hover{background:#0649ff}.Button_button__2Lf63.Button_primary__I_9I9:active{background:#0038d2}.Button_button__2Lf63.Button_danger__5o5di{background:#fa5252;color:#fff}.Button_button__2Lf63.Button_danger__5o5di:hover{background:#f92020}.Button_button__2Lf63.Button_danger__5o5di:active{background:#e00606}

.TextInput_textInput__30LPe{display:flex}.TextInput_textInput__30LPe .TextInput_input__1zwux{width:100%;display:flex;position:relative;box-sizing:border-box;overflow:hidden;background:#fff;border-radius:4px;transition:border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1),box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);box-shadow:inset 0 0 0 1px #dee2e6,0 1px 2px 0 rgba(0,0,0,0.05)}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_icon__3TNUA{color:#868e96;padding:10px}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_icon__3TNUA svg{font-size:18px}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_placeholder__2JlfI{font-size:14px;position:absolute;top:0;bottom:0;margin:auto;left:16px;height:14px;pointer-events:none;color:#868e96;transition:opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1),transform 0.15s cubic-bezier(0.4, 0, 0.2, 1)}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_placeholder__2JlfI.enter{opacity:0;transform:translate(0px, 2px)}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_placeholder__2JlfI.enter-active{opacity:1;transform:translate(0px, 0px)}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_placeholder__2JlfI.exit{opacity:1;transform:translate(0px, 0px)}.TextInput_textInput__30LPe .TextInput_input__1zwux .TextInput_placeholder__2JlfI.exit-active{opacity:0;transform:translate(0px, 2px)}.TextInput_textInput__30LPe .TextInput_input__1zwux input{font-size:14px;border:none;box-sizing:border-box;width:100%;height:100%;padding:12px 16px;background:none}.TextInput_textInput__30LPe.TextInput_hasIcon__3_XGa .TextInput_placeholder__2JlfI{left:38px}.TextInput_textInput__30LPe.TextInput_hasIcon__3_XGa input{padding-left:0}.TextInput_textInput__30LPe.TextInput_isFocus__gcJl7 .TextInput_input__1zwux{box-shadow:inset 0 0 0 1px #396eff,0 1px 2px 0 rgba(0,0,0,0.05)}

.Home_home__1JeZt .Home_form__1KYEF{padding:20px;display:flex}.Home_home__1JeZt .Home_form__1KYEF:first-child{padding-bottom:0}.Home_home__1JeZt .Home_form__1KYEF .Home_inner__3VTnx{display:flex;position:relative;width:100%}.Home_home__1JeZt .Home_form__1KYEF .Home_inner__3VTnx .Home_input__XoWfC{width:calc(100% - 70px);max-width:100%;min-width:auto}.Home_home__1JeZt .Home_form__1KYEF .Home_inner__3VTnx .Home_input__XoWfC>div{border-top-right-radius:0 !important;border-bottom-right-radius:0 !important}.Home_home__1JeZt .Home_form__1KYEF .Home_inner__3VTnx .Home_button__1YwjY{width:70px;border-top-left-radius:0 !important;border-bottom-left-radius:0 !important}.Home_instance__2QxFM{display:flex;flex-direction:column;margin-bottom:20px;box-shadow:0 4px 12px 0 rgba(0,0,0,0.1);font-size:14px;line-height:1.5;letter-spacing:.5px}.Home_instance__2QxFM .Home_instance2__2XwR_{display:flex}.Home_instance__2QxFM .Home_description__1R4FB{padding:10px;font-size:14px;line-height:1.5;letter-spacing:.5px;border-bottom:solid #efefef 1px;margin-bottom:10px}

.Loading_loading__3SgGS{position:absolute;left:0;top:0;right:0;bottom:0;margin:auto;z-index:99;-webkit-animation:Loading_rotator__QXDn_ 1.4s linear infinite;animation:Loading_rotator__QXDn_ 1.4s linear infinite;pointer-events:none}.Loading_loading__3SgGS .Loading_path__jPbfC{stroke-dasharray:93.5;stroke-dashoffset:0;transform-origin:center;-webkit-animation:Loading_dash__1qWzT 1.4s ease-in-out infinite;animation:Loading_dash__1qWzT 1.4s ease-in-out infinite;stroke:#396eff}.Loading_loading__3SgGS.Loading_white__AUmY0 .Loading_path__jPbfC{stroke:#fff}@-webkit-keyframes Loading_rotator__QXDn_{0%{transform:rotate(0deg)}100%{transform:rotate(270deg)}}@keyframes Loading_rotator__QXDn_{0%{transform:rotate(0deg)}100%{transform:rotate(270deg)}}@-webkit-keyframes Loading_dash__1qWzT{0%{stroke-dashoffset:93.5}50%{stroke-dashoffset:23.375;transform:rotate(135deg)}100%{stroke-dashoffset:93.5;transform:rotate(450deg)}}@keyframes Loading_dash__1qWzT{0%{stroke-dashoffset:93.5}50%{stroke-dashoffset:23.375;transform:rotate(135deg)}100%{stroke-dashoffset:93.5;transform:rotate(450deg)}}

